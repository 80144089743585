import Vue from "vue";
import VueRouter from "vue-router";

const HomeView = () => import("@/views/HomeView.vue");
const RecoverPasswordView = () => import("@/views/RecoverPasswordView.vue");
const UpdateRecoverPasswordView = () =>
  import("@/views/UpdateRecoverPasswordView.vue");
const WorkspaceView = () => import("@/views/WorkspaceView.vue");
const PacemakerDetailsView = () => import("@/views/PacemakerDetailsView.vue");
const ResourceVisualizerView = () =>
  import("@/views/ResourceVisualizerView.vue");
const ErrorView = () => import("@/views/ErrorView.vue");

import HomeGuard from "@/guards/HomeGuard.js";
import WorkspaceGuard from "@/guards/WorkspaceGuard";
import RecoverPasswordGuard from "@/guards/RecoverPasswordGuard";
import UpdateRecoverPasswordGuard from "@/guards/UpdateRecoverPasswordGuard";
import PacemakerDetailsGuard from "@/guards/PacemakerDetailsGuard";
import ResourceVisualizerGuard from "@/guards/ResourceVisualizerGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: HomeGuard,
  },
  {
    path: "/recuperar-palavra-passe",
    name: "recover-password",
    component: RecoverPasswordView,
    beforeEnter: RecoverPasswordGuard,
  },
  {
    path: "/recuperar-palavra-passe/alterar",
    name: "update-recover-password",
    component: UpdateRecoverPasswordView,
    beforeEnter: UpdateRecoverPasswordGuard,
  },
  {
    path: "/area-de-trabalho",
    name: "workspace",
    component: WorkspaceView,
    beforeEnter: WorkspaceGuard,
  },
  {
    path: "/detalhe-pacemaker/:id",
    name: "pacemaker-info",
    component: PacemakerDetailsView,
    beforeEnter: PacemakerDetailsGuard,
  },
  {
    path: "/ver-anexo/:pacemakerId/:attachmentId",
    name: "attachment-visualizer",
    component: ResourceVisualizerView,
    beforeEnter: ResourceVisualizerGuard,
  },
  {
    path: "/ver-relatorio/:pacemakerId/:patientId",
    name: "report-visualizer",
    component: ResourceVisualizerView,
    beforeEnter: ResourceVisualizerGuard,
  },
  {
    path: "/ver-relatorio-paciente/:patientId",
    name: "report-visualizer-patient",
    component: ResourceVisualizerView,
  },
  { path: "*", component: ErrorView },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
