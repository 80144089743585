import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.$route.name && _vm.isAuthenticatedRoute(_vm.$route.name))?_c('navbar'):_vm._e(),_c(VMain,{class:{
      'background-image': _vm.$route.name && !_vm.isAuthenticatedRoute(_vm.$route.name),
      'background-light': _vm.$route.name && _vm.isAuthenticatedRoute(_vm.$route.name),
    }},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('notification-component')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }