<template>
  <v-snackbar
    :color="notification.type"
    :timeout="notification.timeout"
    v-model="notification.show"
    top
  >
    <span class="source-sans-pro fs-message-box">{{ notification.text }}</span>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificationComponent",

  computed: {
    ...mapGetters(["notification"]),
  },
};
</script>
