import axios from "axios";
import Vue from "vue";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

import isAuthenticatedRoute from "@/helpers/isAuthenticatedRoute.js";

import "vue-loading-overlay/dist/vue-loading.css";

import "@/assets/css/main.css";

Vue.config.productionTip = false;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error.response.status === 401 &&
      isAuthenticatedRoute(router.currentRoute.name)
    ) {
      store.commit("CLEAN_SESSION");
      await router.push({ name: "home" }).catch(() => {});
      return new Promise(() => {});
    } else {
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
