import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  async login(username, password) {
    return await axios.post(`${process.env.VUE_APP_AUTH_API_ENDPOINT}/`, {
      username,
      password,
    });
  },
  async logout() {
    return await axios.delete(`${process.env.VUE_APP_AUTH_API_ENDPOINT}/`);
  },
  async getSession() {
    return await axios.get(`${process.env.VUE_APP_AUTH_API_ENDPOINT}/`);
  },
  async updatePassword(id, password, newPassword) {
    return await axios.put(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/password/${id}`,
      {
        password,
        newPassword,
      }
    );
  },
  async forgotPassword(username) {
    const params = new URLSearchParams();
    params.append("username", username);

    return await axios.get(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/recovery`,
      {
        params,
      }
    );
  },
  async recoverPassword(token, nid, password) {
    return await axios.put(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/recovery`,
      {
        token,
        nid,
        password,
      }
    );
  },
  async insertBookmark(name, url) {
    return await axios.post(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/bookmarks`,
      {
        name,
        url,
      }
    );
  },
  async getBookmarks() {
    return await axios.get(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/bookmarks`
    );
  },
  async deleteBookmark(uuid) {
    return await axios.delete(
      `${process.env.VUE_APP_AUTH_API_ENDPOINT}/bookmarks/${uuid}`
    );
  },
};
