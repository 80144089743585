<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <v-card>
      <v-card-title color="primary">
        <span class="text-h6">Gerir favoritos</span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="dialog = false" class="close-dialog">
          <v-icon small class="no-bg" color="details">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form @submit.prevent="handleUpdatePassword">
        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" class="pb-1 pt-0">
              <div class="font-weight-bold primary--text">Inserir favorito</div>
            </v-col>
            <v-col cols="4" class="py-0">
              <span class="text-caption primary--text label pt-0"> Nome </span>
              <span class="label-required pl-1">*</span>
              <v-text-field
                dense
                v-model="name"
                solo
                hide-details
                class="input-light"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <span class="text-caption primary--text label pt-0"> URL </span>
              <span class="label-required pl-1">*</span>
              <v-text-field
                dense
                v-model="url"
                solo
                hide-details
                class="input-light"
              />
            </v-col>
            <v-col cols="2" class="py-0">
              <v-btn
                rounded
                depressed
                color="primary"
                small
                @click="insertBookmark"
                style="color: var(--v-secondary-base)"
                class="mt-7"
                block
                :disabled="loading === true"
              >
                <span>Guardar</span>
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="label-required">*</span>
              <span class="label-light text-caption">
                Campos de preenchimento obrigatório
              </span>
            </v-col>
            <v-col cols="12" class="pb-1 pt-4">
              <div class="font-weight-bold primary--text">
                Listagem de favoritos
              </div>
            </v-col>
            <v-col cols="12">
              <v-simple-table dense class="mb-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Nome</th>
                      <th class="text-left">URL</th>
                      <th class="text-left">Acções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="bookmarks.length > 0">
                      <tr v-for="bookmark in bookmarks" :key="bookmark.uuid">
                        <td>
                          {{ bookmark.name }}
                        </td>
                        <td>
                          {{ bookmark.url }}
                        </td>
                        <td style="width: 60px">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                @click="handleDeleteBookmark(bookmark.uuid)"
                                class="btn-icon"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon x-small>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar favorito</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td
                        style="text-align: center; vertical-align: middle"
                        colspan="3"
                      >
                        Não existem favoritos a ser mostrados
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import authenticationService from "@/services/authentication.js";

export default {
  name: "UpdatePasswordComponent",

  data() {
    return {
      dialog: false,
      bookmarks: [],
      name: "",
      url: "",
      loading: false,
    };
  },

  async mounted() {
    await this.updateBookmarks();
  },

  methods: {
    ...mapActions(["showNotification"]),
    async handleDeleteBookmark(uuid) {
      if (confirm("Tem a certeza que deseja eliminar este favorito") === true) {
        try {
          await authenticationService.deleteBookmark(uuid);
          await this.updateBookmarks();
          this.$emit("updateList");
        } catch (err) {
          console.error(err);
          this.showNotification({
            type: "error",
            text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
          });
        }
      }
    },
    async updateBookmarks() {
      try {
        const { data } = await authenticationService.getBookmarks();
        this.bookmarks = data;
      } catch (err) {
        console.error(err);
        this.showNotification({
          type: "error",
          text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
        });
      }
    },
    async insertBookmark() {
      this.loading = true;
      if (this.name === "") {
        this.showNotification({
          type: "error",
          text: "Campo 'Nome' é obrigatório.",
        });
        this.loading = false;
        return;
      }

      if (this.url === "") {
        this.showNotification({
          type: "error",
          text: "Campo 'URL' é obrigatório.",
        });
        this.loading = false;
        return;
      }

      try {
        await authenticationService.insertBookmark(this.name, this.url);
        await this.updateBookmarks();
        this.$emit("updateList");
      } catch (err) {
        this.showNotification({
          type: "error",
          text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
        });
        this.loading = false;
        return;
      }

      this.name = "";
      this.url = "";
      this.loading = false;
      this.showNotification({
        type: "success",
        text: "Favorito adicionado com sucesso.",
      });
    },
  },
};
</script>
