<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <v-card>
      <v-card-title color="primary">
        <span class="text-h6">Alterar palavra-passe</span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="dialog = false" class="close-dialog">
          <v-icon small class="no-bg" color="details">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form @submit.prevent="handleUpdatePassword">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="label-light">Palavra-passe atual</span>
                <v-text-field
                  v-model="old_password"
                  type="password"
                  solo
                  hide-details
                  class="input-light"
                />
                <span class="label-light">Nova palavra-passe</span>
                <v-text-field
                  v-model="new_password"
                  type="password"
                  solo
                  hide-details
                  class="input-light"
                />
                <span class="label-light">Confirme nova palavra-passe</span>
                <v-text-field
                  v-model="confirm_password"
                  type="password"
                  solo
                  hide-details
                  class="input-light"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pl-3 mb-4 mr-5 btn-light"
            type="submit"
            rounded
            :disabled="loading === true"
          >
            Gravar alterações
            <v-icon right dark> mdi-chevron-right </v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import authenticationService from "@/services/authentication.js";

export default {
  name: "UpdatePasswordComponent",

  data() {
    return {
      dialog: false,
      old_password: "",
      new_password: "",
      confirm_password: "",
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["session"]),
  },

  methods: {
    ...mapActions(["showNotification"]),
    async handleUpdatePassword() {
      this.loading = true;

      if (this.old_password === "") {
        this.showNotification({
          type: "error",
          text: "Campo 'Palavra-passe atual' é obrigatório.",
        });
        this.loading = false;
        return;
      }

      if (this.new_password === "") {
        this.showNotification({
          type: "error",
          text: "Campo 'Nova palavra-passe' é obrigatório.",
        });
        this.loading = false;
        return;
      }

      if (this.confirm_password === "") {
        this.showNotification({
          type: "error",
          text: "Campo 'Confirme nova palavra-passe' é obrigatório.",
        });
        this.loading = false;
        return;
      }

      if (this.new_password !== this.confirm_password) {
        this.showNotification({
          type: "error",
          text: "As palavras-passes fornecidas nos campos 'Nova palavra-passe' e 'Confirme nova palavra-passe' têm de coincidir.",
        });
        this.loading = false;
        return;
      }

      if (this.old_password === this.new_password) {
        this.showNotification({
          type: "error",
          text: "A palavra-passe fornecidas no campo 'Palavra-passe atual' coincide com a palavra-passe fornecidas no campo 'Nova palavra-passe'",
        });
        this.loading = false;
        return;
      }

      try {
        await authenticationService.updatePassword(
          this.session.id,
          this.old_password,
          this.new_password
        );

        this.old_password = "";
        this.new_password = "";
        this.confirm_password = "";
      } catch (err) {
        if (err.response.status === 400) {
          this.showNotification({
            type: "error",
            text: "A palavra-passe fornecidas no campo 'Palavra-passe atual' não se encontra correta.",
          });
        } else {
          this.showNotification({
            type: "error",
            text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
          });
        }
        this.loading = false;
        return;
      }

      this.showNotification({
        type: "success",
        text: "Palavra-passe alterada com sucesso.",
      });

      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>
