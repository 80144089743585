const openRoutes = [
  "home",
  "recover-password",
  "update-recover-password",
  "report-visualizer-patient",
];

export default (routeName) => {
  if (openRoutes.indexOf(routeName) !== -1) {
    return false;
  } else {
    return true;
  }
};
