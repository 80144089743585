import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#314F72",
        secondary: "#EFEFEF",
        details: "#F59084",
        details_secondary: "#C2D5EB",
        background: "#1F3147",
        background_light: "#EFEFEF",
      },
    },
    options: { customProperties: true },
  },
});
