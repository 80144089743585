<template>
  <v-app>
    <navbar v-if="$route.name && isAuthenticatedRoute($route.name)" />
    <v-main
      :class="{
        'background-image': $route.name && !isAuthenticatedRoute($route.name),
        'background-light': $route.name && isAuthenticatedRoute($route.name),
      }"
    >
      <router-view :key="$route.fullPath" />
    </v-main>
    <notification-component />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

import isAuthenticatedRoute from "@/helpers/isAuthenticatedRoute.js";
import authenticationService from "@/services/authentication.js";
import Navbar from "@/components/NavbarComponent.vue";
import NotificationComponent from "./components/NotificationComponent.vue";

export default {
  name: "App",

  components: { Navbar, NotificationComponent },

  async created() {
    this.$nextTick(async () => {
      if (isAuthenticatedRoute(this.$route.name) === false) {
        try {
          const resp = await authenticationService.getSession();
          if (resp && resp.data) {
            this.updateSession(resp.data);
          } else {
            this.cleanSession();
          }
        } catch (err) {
          this.cleanSession();
        }
      }
    });
  },

  methods: {
    ...mapActions(["updateSession", "cleanSession"]),
    isAuthenticatedRoute,
  },
};
</script>
