<template>
  <v-app-bar
    elevation="3"
    app
    color="primary"
    height="64px"
    :class="{ 'px-10': $vuetify.breakpoint.width > 1000 }"
  >
    <div
      class="text-h6 secondary--text"
      @click="$router.push({ name: 'workspace' })"
    >
      PACECARE
    </div>
    <v-spacer />

    <v-btn
      v-for="bookmark in bookmarks"
      :key="bookmark.uuid"
      depressed
      class="settings mr-8"
      color="secondary"
      text
      @click="handleURLClick(bookmark.url)"
    >
      {{ bookmark.name }}
    </v-btn>
    <div
      v-if="session && session.name"
      class="text-paragraph secondary--text mr-8"
    >
      Olá, {{ session.name }}
    </div>
    <v-menu offset-y style="z-index: 250">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          class="settings"
          color="secondary"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="settings">mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-hover v-slot="{ hover }">
          <v-list-item @click="logout" :class="{ 'on-hover-list': hover }">
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <div :class="{ 'on-hover-list': hover }">
            <update-password-dialog-component>
              <template #default="{ attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title> Alterar palavra-passe </v-list-item-title>
                </v-list-item>
              </template>
            </update-password-dialog-component>
          </div>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <div :class="{ 'on-hover-list': hover }">
            <update-bookmarks-dialog-component @updateList="updateList">
              <template #default="{ attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title> Gerir favoritos </v-list-item-title>
                </v-list-item>
              </template>
            </update-bookmarks-dialog-component>
          </div>
        </v-hover>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import authenticationService from "@/services/authentication.js";
import UpdatePasswordDialogComponent from "@/components/UpdatePasswordDialogComponent.vue";
import UpdateBookmarksDialogComponent from "@/components/UpdateBookmarksDialogComponent.vue";

export default {
  name: "NavbarComponent",

  components: { UpdatePasswordDialogComponent, UpdateBookmarksDialogComponent },

  data() {
    return {
      bookmarks: [],
    };
  },

  computed: {
    ...mapGetters(["session"]),
  },

  async mounted() {
    await this.updateList();
  },

  methods: {
    ...mapActions(["cleanSession", "showNotification"]),
    async updateList() {
      try {
        const { data } = await authenticationService.getBookmarks();
        this.bookmarks = data;
      } catch (err) {
        console.error(err);
        this.showNotification({
          type: "error",
          text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
        });
      }
    },
    async logout() {
      try {
        await authenticationService.logout();
        this.cleanSession();
        this.$router.push({ name: "home" });
      } catch (err) {
        console.error(err);
        this.showNotification({
          type: "error",
          text: "Ocorreu um erro de servidor. Por favor tente mais tarde.",
        });
      }
    },
    handleURLClick(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
