import store from "@/store/index.js";

export default (to, from, next) => {
  const session = store.getters.session;

  if (session && session.id) {
    next({ name: "workspace" });
  } else {
    next();
  }
};
