import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      paths: ["session"],
    }),
  ],
  state: {
    session: null,
    notification: {
      type: "alert",
      timeout: 5000,
      show: false,
      text: "",
    },
    electrode_brand_items: null,
    electrode_model_items: null,
    electrode_fixation_items: null,
    electrode_location_items: null,
  },
  getters: {
    session: (state) => {
      return state.session;
    },
    notification: (state) => {
      return state.notification;
    },
    electrode_brand_items: (state) => {
      return state.electrode_brand_items;
    },
    electrode_model_items: (state) => {
      return state.electrode_model_items;
    },
    electrode_fixation_items: (state) => {
      return state.electrode_fixation_items;
    },
    electrode_location_items: (state) => {
      return state.electrode_location_items;
    },
  },
  mutations: {
    CLEAN_SESSION(state) {
      state.session = null;
    },
    UPDATE_SESSION(state, session) {
      state.session = session;
    },
    UPDATE_ELEC_BRAND_ITEMS(state, items) {
      state.electrode_brand_items = items;
    },
    UPDATE_ELEC_MODEL_ITEMS(state, items) {
      state.electrode_model_items = items;
    },
    UPDATE_ELEC_FIXATION_ITEMS(state, items) {
      state.electrode_fixation_items = items;
    },
    UPDATE_ELEC_LOCATION_ITEMS(state, items) {
      state.electrode_location_items = items;
    },
    SHOW_NOTIFICATION(state, { type, text }) {
      state.notification.show = false;
      setTimeout(() => {
        state.notification.show = true;
        state.notification.text = text;
        state.notification.type = type;
      });
    },
  },
  actions: {
    cleanSession({ commit }) {
      return commit("CLEAN_SESSION");
    },
    updateSession({ commit }, session) {
      return commit("UPDATE_SESSION", session);
    },
    showNotification({ commit }, { type, text }) {
      return commit("SHOW_NOTIFICATION", { type, text });
    },
    updateElectrodeBrandItems({ commit }, items) {
      return commit("UPDATE_ELEC_BRAND_ITEMS", items);
    },
    updateElectrodeModelItems({ commit }, items) {
      return commit("UPDATE_ELEC_MODEL_ITEMS", items);
    },
    updateElectrodeLocationItems({ commit }, items) {
      return commit("UPDATE_ELEC_LOCATION_ITEMS", items);
    },
    updateElectrodeFixationItems({ commit }, items) {
      return commit("UPDATE_ELEC_FIXATION_ITEMS", items);
    },
  },
  modules: {},
});
